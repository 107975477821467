@import '../../global.scss';
.home-wrapper {
    display: grid;
    align-items: flex-start;
    align-content: flex-start;
    min-height: 60vh;
    width: 100%;
    background: url('../../Images/header.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    .home-content-wrapper {
        display: grid;
        padding: 1rem 4% 2rem 4%;
        h1 {
            color: rgba($color: #151515, $alpha: 1.0);
        }
        a {
            color: rgba($color: #151515, $alpha: 1.0);
        }
        .home-nav {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 75px;
            ul {
                display: flex;
                list-style-type: none;
                li {
                    a {
                        margin-left: 1.5rem;
                        text-transform: uppercase;
                        color: rgba($color: #000000, $alpha: .5);
                        font-size: .9rem;
                        transition: ease-in-out 200ms;
                        &:hover {
                            color: $main-color;
                        }
                    }
                }
            }
        }
        .home-cta {
            display: grid;
            margin-top: 5rem;
            padding: 1rem 1rem;
            background: rgba($color: $main-color, $alpha: .9);
            box-shadow: 0px 6px 6px rgba($color: #000000, $alpha: .25);
            p {
                color: white;
            }
        }
    }

}
.home-overview {
    .seperator {
        background-color: #ffffff;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000000' fill-opacity='0.1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");    
        h2 {
        text-align: center;
        color: rgb(22, 22, 22);
            text-transform: uppercase;
            padding: 1rem 0rem;
        }
    }
    .home-overview-content {
        display: grid;
        margin-top: 1rem;
        row-gap: 2rem;
        padding: 1rem 4% 2rem 4%;
        align-items: flex-start;
        align-content: flex-start;
        .card {
            display: grid;
            align-content: flex-start;
            min-height: 200px;
            box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: .25);
            border-top-right-radius: .5rem;

            h3 {
                background-color: #232323;
                background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23eaeaea' fill-opacity='0.06' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
                padding: 1rem .5rem;
                height: max-content;
                text-align: center;
                color: $main-color;
                border-top-right-radius: .5rem;
            }
            p { 
                margin: 0px;
                font-size: .85rem;
                padding: .5rem .5rem 1rem .5rem;
                color: rgba($color: #383838, $alpha: 1.0);
            }
        }
    }
}

@include responsive(small) {

}
@include responsive(medium) {
    .home-wrapper {
        .home-content-wrapper {
            display: grid;
            padding: 1rem 10% 2rem 10%;
        }
    }
    .home-overview {
        .home-overview-content {
            padding: 1rem 8% 2rem 8%;
            grid-template-columns: repeat(3, minmax(200px, 1fr) );
            column-gap: .5rem;
         }
    }
}
@include responsive(large) {
    .home-wrapper {
        .home-content-wrapper {
            display: grid;
            padding: 1rem 12% 2rem 12%;
        }
    }
    .home-overview {
        .home-overview-content {
            padding: 1rem 12% 2rem 12%;
            grid-template-columns: repeat(3, minmax(250px, 1fr) );

        }
    }
}