@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

$main-color: rgba($color: #FFB100, $alpha: 1.0);
$breakpoints: ('small': 400px, 'medium': 768px, 'large': 1200px);

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}
.global-wrapper {
    background: url('./Images/header.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}
.padded-wrapper {
    padding: 1rem 4% 2rem 4%;
}

p {
    margin-top: 1rem;
    color: rgba($color: #dcdcdc, $alpha: 1.0);
}

@mixin responsive($breakpoint) {
    $value: map-get($breakpoints, $breakpoint);
    @if $value !=null {
        // Prints a media query based on the value
        @media (min-width: $value) {
            @content;
        }
    }
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Please make sure it is defined in `$breakpoints` map.";
    }
}

@include responsive(small) {

}
@include responsive(medium) {
    .padded-wrapper {
        padding: 1rem 10% 2rem 10%;
    }
}
@include responsive(large) {
    .padded-wrapper {
        padding: 20rem 0rem 0rem 0rem;
    }
}