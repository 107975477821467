@import '../../global.scss';

.header {
    display: grid;
    align-content: flex-start;
    position: relative;
    margin-top: 50px;
    height: 250px;
    overflow: hidden;
    scroll-behavior: initial;
    padding: 1rem 4%;
    &::-webkit-scrollbar {
        width: 0rem;
        display: none;
    }
    object-position: bottom;
    img {
        overflow: hidden;
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}


@include responsive(small) {

}
@include responsive(medium) {
    .header {
        height: 400px;
        h1 {
            font-size: 5rem;
            color: rgba($color: #ebebeb, $alpha: 1.0);
        }
        h2 {
            font-size: 3rem;
            color: rgba($color: #c4c4c4, $alpha: 1.0);
        }
    }
 
}
@include responsive(large) {

}