@import '../../global.scss ';


.navigation {
    display: grid;
    background: rgba($color: #000000, $alpha: .95);
    box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: .25);
    font-family: 'Saira Semi Condensed', sans-serif;
    width: 100%;
    position: fixed;
    height: 60px;
    z-index: 1;
    .desktop {
        display: none;
    }
    .mobile {
        display: flex;
        justify-content: space-between;
        height: 60px;
        align-items: center;
        padding: 0rem 4%;
        svg {
            font-size: 1.5em;
            color: rgba($color: $main-color, $alpha: 1.0);
        }
        ul {
            display: flex;
            list-style-type: none;
            li {
                img {
                    height: 30px;
                }
                a {
                    margin-left: 1.5rem;
                    text-transform: uppercase;
                    color: rgba($color: #000000, $alpha: .5);
                    font-size: .9rem;
                    transition: ease-in-out 200ms;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
}


@include responsive(small) {
    .navigation {
        .desktop {
            display: none;
        }
    }
}
@include responsive(medium) {
    .navigation {
        .desktop {
                display: flex;
                justify-content: space-between;
                height: 60px;
                align-items: center;
                align-content: center;
                padding: 0rem 10%;
                color: white;
                svg {
                    font-size: 1.5em;
                    color: rgba($color: white, $alpha: 1.0);
                }
                ul {
                    display: flex;
                    height: 60px;
                    list-style-type: none;
                    align-items: center;
                    align-content: center;
                    li {
                        display: flex;
                        align-items: center;
                        img {
                            height: 30px;
                        }
                        a {
                            display: flex;
                            align-items: center;
                            align-content: center;
                            margin-left: 1.5rem;
                            text-transform: uppercase;
                            font-weight: bold;
                            text-decoration: none;
                            color: rgba($color: white, $alpha: 1);
                            font-size: .85rem;
                            transition: ease-in-out 200ms;
                            svg {
                                align-self: center;
                                margin-left: .25rem;
                                transition: ease-in-out 200ms;
                                font-size: .75rem;
                            }
                            &:hover {
                                svg {
                                    color: $main-color;
                                }
                                color: rgba($color: $main-color, $alpha: 1.0);               
                            }
                        }
                    }
                }
            }
        .mobile {
            display: none;
        }
    }
}

@include responsive(large) {
    .navigation {
        .desktop {
                display: flex;
                justify-content: space-between;
                height: 60px;
                align-items: center;
                padding: 0rem 15%;
                color: white;
        }
    }
}