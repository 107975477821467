@import '../../global.scss';

.footer {
    display: grid;
    align-self: flex-end;
    align-content: flex-end;
    font-size: .75rem;
    height: 200px;
    background: rgba($color: #1a1a1a, $alpha: 1.0);
    bottom: 0;
    color: white;
    justify-content: center;
    img {
        width: 150px;
    }
}